<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Serbest Tahakkuk</h3>
        </div>
        <AddResidentsToBatchForm ref="batchForm" />
        <v-col class="text-right" v-if="can('edit-assessment')">
          <v-btn
            class="ms-2"
            v-if="id && originalData.applied === false"
            small
            color="pink"
            outlined
            @click="handleCreateDebtsClick"
            ref="createDebtsButton"
            :loading="isLoading"
          >
            Borçlandır
          </v-btn>
          <v-btn
            class="ms-2"
            v-if="
              id &&
              originalData.applied === true &&
              (originalData.debitted_at !== null ||
                originalData.issues_on > new Date().toJSON().split('T')[0])
            "
            small
            color="pink"
            outlined
            @click="handleDeleteDebtsClick"
            ref="deleteDebtsButton"
            :loading="isLoading"
          >
            Borçlandırma İptal
          </v-btn>
          <v-btn
            class="ms-2"
            v-if="
              id &&
              originalData.applied === true &&
              originalData.debitted_at !== null
            "
            small
            color="pink"
            outlined
            @click="handleSendNotificationClick"
            ref="sendNotificationButton"
            :loading="isLoading"
          >
            Bildirim Gönder
          </v-btn>
          <!-- <v-btn
            v-if="id"
            class="ms-2"
            small
            color="pink"
            outlined
            :loading="isLoading"
          >
            Makbuz Yazdır
          </v-btn> -->
          <v-btn
            class="me-2 ml-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="id"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>
      <div class="card-body" v-if="id || clusterId">
        <p
          class="text-uppercase"
          v-if="originalData.queued_at && originalData.debitted_at === null"
        >
          <strong>
            Borçlandırma işlemi kuyruğa alındı:
            {{ new Date(originalData.queued_at).toLocaleString() }}
          </strong>
        </p>
        <v-progress-linear
          v-if="originalData.queued_at && originalData.debitted_at === null"
          color="pink"
          indeterminate
          class="mb-3"
        />

        <div v-if="id && originalData.applied">
          <h3 class="ps-5">Özet</h3>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Toplam Tutar</th>
                  <th>Toplam Gecikme</th>
                  <th>Toplam Ödenen</th>
                  <th>Kalan</th>
                  <th>Kalan Gecikme</th>
                  <th>İcra</th>
                  <th>İcra Tahsilat</th>
                  <th>İcra Kalan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <rs-table-cell-number price :value="totals.total_amount" />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.total_deferment"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.total_collecting_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.remaining_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.remaining_deferment"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.enforcement_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.enforcement_collecting_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.enforcement_remaining_amount"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="my-5" />
        </div>
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col sm="3">
              <rs-text-field
                type="number"
                step="1"
                min="2019"
                :max="new Date().getFullYear() + 2"
                label="Yıl"
                :rules="[
                  rules.required,
                  rules.min(formData.name, 2019),
                  rules.max(formData.name, new Date().getFullYear() + 2),
                ]"
                v-model="formData.year"
                :disabled="formData.is_transferred || formData.is_deferment"
                :readonly="!editable"
                :filled="!editable"
                @change="handlePeriodChange"
              />
            </v-col>
            <v-col sm="3">
              <rs-select
                label="Ay"
                :rules="[rules.required]"
                v-model="formData.month"
                :items="monthListLocal"
                :disabled="formData.is_transferred || formData.is_deferment"
                :readonly="!editable"
                :filled="!editable"
                @change="handlePeriodChange"
              />
            </v-col>
            <v-col sm="3">
              <rs-select
                label="Borçlandırma Türü"
                :rules="[rules.required]"
                v-model="formData.income_type_id"
                :items="incomeTypeList"
                :readonly="!editable"
                :filled="!editable"
                required
                @change="handleIncomeTypeChange"
              />
            </v-col>
            <v-col sm="3">
              <rs-select-project
                label="Proje"
                v-model="formData.project_id"
                :readonly="!editable"
                :filled="!editable"
                :clearable="editable"
              />
            </v-col>
            <v-col sm="3">
              <rs-datepicker
                label="Tahakkuk Tarihi"
                v-model="formData.issues_on"
                :rules="!editable ? [] : [rules.required]"
                :disabled="
                  !editable || formData.is_transferred || formData.is_deferment
                "
                required
              />
            </v-col>
            <v-col sm="3">
              <rs-datepicker
                label="Son Ödeme Tarihi"
                v-model="formData.last_payment_on"
                :rules="!editable ? [] : [rules.required]"
                :disabled="!editable"
              />
            </v-col>
            <v-col sm="3">
              <rs-select
                label="Hesaplama Tipi"
                :rules="[rules.required]"
                v-model="formData.calculation_type_id"
                :items="assessmentCalculationTypeList"
                :readonly="!editable"
                :filled="!editable"
                required
                @change="populateHouseList"
              />
            </v-col>
            <v-col sm="3">
              <rs-select-block
                v-if="formData.calculation_type_id === 2"
                label="Bloklar"
                :rules="[rules.required]"
                v-model="formData.block_ids"
                :readonly="!editable"
                :filled="!editable"
                multiple
                required
                @change="populateHouseList"
              />
            </v-col>
            <v-col sm="3">
              <rs-select
                label="Borçlu Tipi"
                :rules="[rules.required]"
                v-model="formData.debitor_type_id"
                :items="debitorTypeList"
                :readonly="!editable"
                :filled="!editable"
                required
                @change="populateHouseList"
              />
            </v-col>
            <v-col sm="3">
              <rs-select
                label="Gecikme Hesaplama Türü"
                :rules="[rules.required]"
                :items="[
                  { id: 'daily', name: 'Günlük' },
                  { id: 'monthly', name: 'Aylık' },
                ]"
                v-model="formData.deferment_calculation_period"
                :readonly="!editable || formData.is_deferment"
                :filled="!editable || formData.is_deferment"
              />
            </v-col>
            <v-col sm="3">
              <rs-text-field
                :label="$t('labels.expired_option_days')"
                :rules="[rules.required]"
                v-model="formData.deferment_grace_period"
                type="number"
                step="1"
                min="0"
                max="60"
                :readonly="!editable || formData.is_deferment"
                :filled="!editable || formData.is_deferment"
              />
            </v-col>
            <v-col sm="3">
              <rs-text-field
                label="Gecikme Faiz Oranı (Aylık)"
                :rules="[rules.required]"
                v-model="formData.deferment_interest_percent"
                prefix="%"
                type="number"
                step="1"
                min="0"
                max="100"
                :readonly="!editable || formData.is_deferment"
                :filled="!editable || formData.is_deferment"
              />
            </v-col>
            <v-col sm="12">
              <rs-text-field
                label="Açıklama"
                :rules="[
                  rules.required,
                  rules.maxLength(formData.description, 100),
                ]"
                v-model="formData.description"
                :readonly="!editable"
                :filled="!editable"
                required
              />
            </v-col>
            <v-col sm="3">
              <v-switch
                v-model="formData.send_push_notifications"
                label="Mobil Bildirim Gönderilsin mi?"
                hide-details="auto"
                :readonly="!editable"
                :filled="!editable"
              />
            </v-col>
            <v-col sm="3">
              <v-switch
                v-model="formData.is_transferred"
                label="Devir Tahakkuk"
                hide-details="auto"
                @change="handleTransferredTypeChange"
                :readonly="
                  !editable ||
                  [13, 14, 15].indexOf(formData.income_type_id) !== -1
                "
                :filled="
                  !editable ||
                  [13, 14, 15].indexOf(formData.income_type_id) !== -1
                "
              />
            </v-col>
            <v-col sm="3">
              <v-switch
                v-model="formData.is_deferment"
                label="Gecikme Tahakkuk"
                hide-details="auto"
                @change="handleDefermentTypeChange"
                :readonly="!editable || formData.income_type_id === 14"
                :filled="!editable || formData.income_type_id === 14"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-5" v-if="!originalData.applied">
            <v-col cols="12">
              <v-btn
                @click="handleTemplateDownloadClick"
                class="btn"
                color="pink"
                outlined
                small
                :disabled="!editable"
                :loading="isLoading"
              >
                XLSX şablon indir
              </v-btn>

              <v-btn
                @click="handleTemplateUploadClick"
                class="btn ms-2"
                color="pink"
                outlined
                small
                :disabled="!editable"
                :loading="isLoading"
              >
                XLSX şablon yükle
              </v-btn>

              <rs-file
                label="Dosya"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                v-if="!disabled"
                @change="handleFileChange"
                class="d-none"
                id="importInput"
              />
            </v-col>
            <v-col>
              <v-data-table
                v-bind="dataTableAttrs"
                :headers="headers"
                :items="houses"
                :loading="isLoading"
                :options.sync="options"
                :items-per-page="20"
                :show-select="false"
                :item-class="houseRowClass"
                @pagination="handlePaginationUpdated"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:item.resident="{ item }">
                  <template v-if="formData.debitor_type_id === 1">
                    {{ item.owner }}
                  </template>
                  <template v-else>
                    {{ item.resident_to_debit }}
                  </template>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.amount="{ item, index }">
                  <rs-money-field
                    v-if="showAmounts"
                    v-model="item.amount"
                    :rules="
                      item.disabled || !editable
                        ? []
                        : [
                            rules.required,
                            rules.min(item.amount, 0.01),
                            rules.max(item.amount, 9999999.99),
                          ]
                    "
                    :disabled="item.disabled || !editable"
                    @paste="handleAmountPaste($event, index)"
                  />
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.activate="{ item }">
                  <v-btn
                    @click="handleHouseToggle(item)"
                    class="btn"
                    color="pink"
                    outlined
                    small
                    :disabled="!editable"
                  >
                    {{ item.disabled ? "Etkinleştir" : "İptal Et" }}
                  </v-btn>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:header.activate>
                  <v-btn
                    @click="handleToggleAllHouses()"
                    class="btn"
                    color="pink"
                    outlined
                    small
                    :disabled="!editable"
                  >
                    {{
                      houses.filter((item) => !item.disabled).length
                        ? "Tümünü İptal Et"
                        : "Tümünü Etkinleştir"
                    }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            v-if="!disabled"
            @submit="handleFormSubmit"
          />
        </v-form>

        <div v-if="id && originalData.applied">
          <v-divider class="my-10" />
          <h3 class="ps-5">Tahakkuklar</h3>

          <v-tabs v-model="selectedTab">
            <v-tab href="#all">Tüm Tahakkuklar</v-tab>
            <v-tab href="#not-collected">Ödemeyenler</v-tab>
            <v-tab href="#collected">Ödeyenler</v-tab>
          </v-tabs>

          <v-tabs-items touchless v-model="selectedTab">
            <v-tab-item class="py-4" value="all">
              <DebtList :assessment-id="id" />
            </v-tab-item>
            <v-tab-item class="py-4" value="not-collected">
              <DebtList :assessment-id="id" :unpaid-only="true" />
            </v-tab-item>
            <v-tab-item class="py-4" value="collected">
              <DebtList :assessment-id="id" :paid-only="true" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>

      <div class="card-body" v-else>
        <p>Tahakkuk eklemek için üst menüden bir toplu yaşam alanı seçin.</p>
      </div>
      <ActionLogList ref="actionLogList" />
    </div>

    <v-tooltip bottom :activator="$refs.deleteDebtsButton">
      <span>Bu tahakkuktan oluşturulmuş tüm borçlar silinir.</span>
    </v-tooltip>
    <v-tooltip bottom :activator="$refs.createDebtsButton">
      <span>Tahakkuk kapsamındaki tüm daireler borçlandırılır.</span>
    </v-tooltip>
    <v-tooltip bottom :activator="$refs.sendNotificationButton">
      <span>Borçlu ve ödememiş kişilere bildirim gönderilir.</span>
    </v-tooltip>

    <rs-confirm
      ref="confirmCreateDebts"
      :loading="isLoading"
      @confirmed="handleCreateDebtsSubmitClick"
      @cancelled="$refs.confirmCreateDebts.hide()"
    />
    <rs-confirm
      ref="confirmDeleteDebts"
      :loading="isLoading"
      @confirmed="handleDeleteDebtsSubmitClick"
      @cancelled="$refs.confirmDeleteDebts.hide()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasPermissions } from "@/view/mixins";
import hasDataTable from "@/view/mixins/hasDataTable";
import DebtList from "../DebtList";
import AddResidentsToBatchForm from "@/view/pages/communication/batch-notifications/AddResidentsToBatchForm";

export default {
  mixins: [hasDataTable, hasForm, hasPermissions],
  components: {
    ActionLogList,
    AddResidentsToBatchForm,
    DebtList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
    cluster: {
      handler() {
        if (this.id) {
          return;
        }

        this.formData.deferment_grace_period =
          this.cluster.deferment_grace_period;
        this.formData.deferment_interest_percent =
          this.cluster.deferment_interest_percent;
        this.formData.deferment_calculation_period =
          this.cluster.deferment_calculation_period;
        this.handlePeriodChange();
      },
      immediate: true,
    },
    houseTypeList: {
      handler() {
        if (this.id) {
          return;
        }

        this.populateHouseList();
      },
      immediate: true,
    },
    "formData.is_transferred"(newValue) {
      if (!this.disabled && newValue) {
        if (!this.cluster || !this.cluster.legal_beginning_date) {
          this.formData.issues_on = null;
          return;
        }

        const split = this.cluster.legal_beginning_date.split("-");

        const date = this.cluster.legal_beginning_date
          ? new Date(Date.UTC(split[0], parseInt(split[1]) - 1, split[2]))
          : null;

        if (!date) {
          return null;
        }

        this.formData.issues_on = new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() - 1
          )
        )
          .toJSON()
          .split("T")[0];
      }
    },
  },
  computed: {
    ...mapGetters([
      "cluster",
      "clusterId",
      "incomeTypeList",
      "assessmentCalculationTypeList",
      "debitorTypeList",
      "monthList",
    ]),
    houseTypeList() {
      return this.cluster && this.cluster.house_types;
    },
    monthListLocal() {
      return this.monthList.map((item, index) => {
        return {
          id: index + 1,
          name: item,
        };
      });
    },
    editable() {
      if (this.disabled) {
        return false;
      }

      if (!this.id === null) {
        return true;
      }

      if (this.originalData.applied) {
        return false;
      }

      return true;
    },
  },
  data() {
    return {
      selectedTab: "all",
      /**
       * Total amounts about assessment
       * @type {Object}
       */
      totals: {},
      houses: [],
      showAmounts: true,
      defermentDisabled: false,
      queueInterval: null,
      formData: {
        assessment_type_id: 3,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        income_type_id: 1,
        project_id: null,
        issues_on: null,
        last_payment_on: null,
        calculation_type_id: 1,
        block_ids: [],
        debitor_type_id: 2,
        description: null,
        deferment_calculation_period: null,
        deferment_grace_period: null,
        deferment_interest_percent: null,
        send_push_notifications: true,
        is_transferred: false,
        is_deferment: false,
      },
      headers: [
        {
          text: "Blok",
          value: "block_name",
          sortable: false,
          searchable: false,
          options: () => this.blocks,
        },
        {
          text: "Kapı Numarası",
          value: "door_number",
          sortable: false,
          searchable: "text",
        },
        {
          text: "BB Tipi",
          value: "house_type_name",
          sortable: false,
          searchable: "select",
          options: () => this.houseTypeList,
        },
        {
          text: "Borçlandırılacak",
          value: "resident",
          sortable: false,
          searchable: "text",
        },
        {
          text: "Tutar",
          value: "amount",
          sortable: false,
          searchable: false,
        },
        {
          text: "",
          value: "activate",
          sortable: false,
          searchable: false,
        },
      ],
      options: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`assessments/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
          this.totals = response.data.data.totals;

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          this.populateHouseList();
          this.updateClusterId(this.originalData.cluster_id);

          if (
            this.originalData.queued_at &&
            this.originalData.debitted_at === null
          ) {
            // we need to check if queue job is finished
            if (this.queueInterval === null) {
              this.queueInterval = window.setInterval(() => this.load(), 10000);
            }
          } else if (this.queueInterval !== null) {
            this.clearQueueInterval();
            this.$toast.success("Bağımsız bölümler borçlandırıldı");
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTransferredTypeChange() {
      if (this.formData.is_transferred && this.cluster.legal_beginning_date) {
        const split = this.cluster.legal_beginning_date.split("-");
        const beginningDate = new Date(this.cluster.legal_beginning_date)
          ? new Date(Date.UTC(split[0], parseInt(split[1]) - 1, split[2]))
          : null;

        if (!beginningDate) {
          return null;
        }
        this.formData.issues_on = new Date(
          Date.UTC(
            beginningDate.getUTCFullYear(),
            beginningDate.getUTCMonth(),
            beginningDate.getUTCDate() - 1
          )
        )
          .toJSON()
          .split("T")[0];
      }
    },
    handleDefermentTypeChange() {
      if (this.formData.is_deferment && this.cluster.legal_beginning_date) {
        this.formData.deferment_interest_percent = 0;
        this.formData.deferment_grace_period = 0;

        const split = this.cluster.legal_beginning_date.split("-");
        const beginningDate = new Date(this.cluster.legal_beginning_date)
          ? new Date(Date.UTC(split[0], parseInt(split[1]) - 1, split[2]))
          : null;

        if (!beginningDate) {
          return null;
        }
        this.formData.issues_on = new Date(
          Date.UTC(
            beginningDate.getUTCFullYear(),
            beginningDate.getUTCMonth(),
            beginningDate.getUTCDate() - 1
          )
        )
          .toJSON()
          .split("T")[0];
      }
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);

      formData.assessment_houses = this.houses.filter((item) => {
        return !item.disabled;
      });

      if (formData.assessment_houses.length === 0) {
        this.$toast.error("En az bir bağımsız bölüm aktif olmalı.");
        return false;
      }

      this.isLoading = true;

      if (formData.calculation_type_id === 1) {
        delete formData.block_ids;
      }

      if (this.id) {
        this.$api
          .put(`assessments/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.originalData = response.data.data;
            if (event && event.closeOnSave) {
              this.handleCancelClick();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/assessments`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");

            this.$router.replace({
              name: "assessments.free.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    populateHouseList() {
      const params = {
        is_active: 1,
      };
      const formList = [];
      this.houses = [];

      this.isLoading = true;

      if (this.formData.calculation_type_id === 2) {
        params.block_name = this.formData.block_ids;
      }

      params.per_page = 2000;
      params.order_by = "door_number";
      params.order = "asc";

      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          const currentData = {};
          if (this.originalData.assessment_houses) {
            for (const index in this.originalData.assessment_houses) {
              const item = this.originalData.assessment_houses[index];
              currentData[item.house_id] = item.amount;
            }
          }

          for (const index in response.data.data) {
            const item = response.data.data[index];
            if (!this.editable) {
              if (currentData[item.id] === undefined) {
                continue;
              }
            }

            formList.push({
              house_id: item.id,
              house_type_name: item.house_type_name,
              door_number: item.door_number,
              block_name: item.block_name,
              resident_to_debit: item.resident_to_debit,
              renter: item.renter,
              owner: item.owner,
              has_multiple_owners: item.has_multiple_owners,
              amount: currentData[item.id] ? currentData[item.id] : 0,
              disabled: currentData[item.id] === undefined ? true : false,
            });
          }

          this.houses = formList;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePeriodChange() {
      if (this.formData.year && this.formData.month) {
        let description = this.formData.year + "-";
        description += this.monthList[parseInt(this.formData.month) - 1];

        if (this.formData.income_type_id) {
          for (const index in this.incomeTypeList) {
            if (
              this.incomeTypeList[index].id === this.formData.income_type_id
            ) {
              description += " " + this.incomeTypeList[index].name;
            }
          }
        }

        this.formData.description = description;

        this.formData.issues_on =
          this.formData.year +
          "-" +
          this.formData.month.toString().padStart(2, "0") +
          "-01";

        const lastPaymentOn = new Date(
          Date.UTC(this.formData.year, this.formData.month, 0)
        );

        let lastPaymentDay = lastPaymentOn.getDate();

        if (this.cluster.fee_last_payment_day) {
          if (this.cluster.fee_last_payment_day < lastPaymentDay) {
            lastPaymentDay = this.cluster.fee_last_payment_day;
            lastPaymentOn.setUTCDate(lastPaymentDay);
          }
        }

        this.formData.last_payment_on = lastPaymentOn.toJSON().split("T")[0];
        this.handleTransferredTypeChange();
      }
    },
    handleCreateDebtsClick() {
      this.$refs.confirmCreateDebts.show(
        "Seçilen tüm bağımsız bölümler borçlandırılacak. Emin misiniz?"
      );
    },
    handleCreateDebtsSubmitClick() {
      if (this.isLoading) return false;

      if (!this.houses.length) {
        this.$toast.error("Borçlandırılacak bağımsız bölüm yok.");
        this.$refs.confirmCreateDebts.hide();
        return;
      }

      this.isLoading = true;

      this.$api
        .post(`assessments/${this.id}/debit`)
        .then(() => {
          // this.$toast.success("Bağımsız bölümler borçlandırıldı.");
          // this.$nextTick(() => this.load());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.confirmCreateDebts.hide();
        })
        .then(() => {
          return this.load();
        })
        .then(() => {
          if (this.originalData.debitted_at !== null) {
            this.$toast.success("Bağımsız bölümler borçlandırıldı.");
          } else {
            this.$toast.warning("Borçlandırma işlemi kuyruğa alındı.", {
              timeout: 10000,
            });
          }
        });
    },
    handleDeleteDebtsClick() {
      this.$refs.confirmDeleteDebts.show(
        "Bu tahakkuktan oluşturulan tüm borçlar silinecek. Emin misiniz?"
      );
    },
    handleDeleteDebtsSubmitClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      this.$api
        .delete(`assessments/${this.id}/debit`)
        .then(() => {
          this.$toast.success("Borçlandırmalar iptal edildi.");
          this.$nextTick(() => this.load());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.confirmDeleteDebts.hide();
        });
    },
    handleSendNotificationClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = { assessment_id: this.id, per_page: 2000 };

      this.$api
        .query("income/debts", { params })
        .then((response) => {
          return response.data.data.map((item) => item.resident_id);
        })
        .then((residentIds) => {
          if (!residentIds.length) {
            this.$toast.info("Bildirim gönderilecek sakin yok.");
          } else {
            this.$refs.batchForm.show(residentIds, 1, {
              assessment_id: this.id,
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleHouseToggle(item) {
      item.disabled = !item.disabled;
      item.amount = 0;
    },
    handleAmountPaste(event, index) {
      if (event.constructor.name === "ClipboardEvent") {
        if (event.clipboardData) {
          const clipboardData = event.clipboardData.getData("text");

          if (!clipboardData || clipboardData.search("\n") === -1) {
            return true;
          }

          event.preventDefault();

          setTimeout(() => {
            const data = clipboardData.split(/\r?\n/g).map((line) => {
              return line.trim().replace(/[^\d,.]+/g, "");
            });

            let clipboardIndex = 0;
            for (
              let formIndex = index;
              formIndex < this.houses.length;
              formIndex++
            ) {
              if (data[clipboardIndex] === undefined) {
                break;
              }

              if (!data[clipboardIndex]) {
                this.houses[formIndex].amount = 0;
                this.houses[formIndex].disabled = true;
              } else {
                this.houses[formIndex].amount = new Number(
                  new Number(data[clipboardIndex]).toFixed(2)
                );
              }
              clipboardIndex++;
            }
          }, 100);

          return false;
        }
      }
    },
    houseRowClass(item) {
      return item.disabled ? "disabled" : null;
    },
    handleToggleAllHouses() {
      let value = false;

      if (this.houses.filter((item) => !item.disabled).length) {
        value = true;
      }

      for (const index in this.houses) {
        this.houses[index].disabled = value;
      }
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Assessment", this.id);
    },
    handlePaginationUpdated() {
      this.showAmounts = false;
      setTimeout(() => (this.showAmounts = true), 50);
    },
    handleTemplateDownloadClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = {
        is_active: 1,
        cluster_id: this.clusterId,
        debitor_type_id: this.formData.debitor_type_id,
      };

      if (this.formData.calculation_type_id === 2) {
        params.block_name = this.formData.block_ids;
      }

      this.$api
        .query("assessment-template", { params })
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateUploadClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post("read-spreadsheet", data, config)
        .then((response) => {
          for (const index in response.data.data) {
            for (const houseIndex in this.houses) {
              if (
                this.houses[houseIndex].house_id ===
                parseInt(response.data.data[index][0])
              ) {
                const amount = parseFloat(response.data.data[index][6]);
                this.houses[houseIndex].amount = amount;

                this.houses[houseIndex].disabled = amount <= 0;
              }
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleIncomeTypeChange(value) {
      this.formData.child_income_type_id = null;
      this.defermentDisabled = false;

      if (value === 14) {
        this.formData.is_deferment = true;
        this.formData.is_transferred = true;
        this.formData.deferment_interest_percent = 0;
        this.formData.deferment_grace_period = 0;
        this.defermentDisabled = true;
      } else if (value === 13) {
        this.formData.is_transferred = true;
        this.formData.is_deferment = false;
      } else if (value === 15) {
        this.formData.is_transferred = true;
        this.formData.is_deferment = false;
        this.formData.deferment_interest_percent = 0;
        this.formData.deferment_grace_period = 0;
        this.defermentDisabled = true;
      } else {
        this.formData.is_transferred = false;
        this.formData.is_deferment = false;
      }

      this.$nextTick(() => this.handlePeriodChange());
    },
    clearQueueInterval() {
      window.clearInterval(this.queueInterval);
      this.queueInterval = null;
    },
  },
  destroyed() {
    if (this.queueInterval !== null) {
      this.clearQueueInterval();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      } else {
        this.handlePeriodChange();
      }
    });
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
tr.disabled {
  td {
    opacity: 1;
    transition: opacity 0.3s ease-out !important;
  }

  td[disabled]:not(:last-of-type) {
    opacity: 0.4;
  }
}
</style>
